import React from 'react';

const SliderCard07 = (props) => {
    return (
        <div className="swiper-slide">
            <div className="slider">
                <a href="/project-details" target="_blank"><img src={props.img} alt="" /></a>
            </div>
        </div>
    );
};

export default SliderCard07;