import React from 'react';
import Sliders07 from '../containers/Sliders07';

const Home07 = () => {
    return (
        <div className="wrapper">
            <Sliders07 />
        </div>
    );
};

export default Home07;