import React from 'react';
import AboutContainer from '../containers/AboutContainer';
import Blogs from '../containers/Blogs';
import FormContainer from '../containers/FormContainer';
import FunFacts from '../containers/FunFacts';
import ProjectGallery from '../containers/ProjectGallery';
import RunningProjects from '../containers/RunningProjects';
import Services from '../containers/Services';
import Sliders from '../containers/Sliders';

const Home01 = () => {
    return (
        <div className="wrapper">
            <Sliders />
            <Services />
            <AboutContainer />
            <FunFacts className='funfacts bg_1' />
            <RunningProjects />
            <ProjectGallery />
            {/* <Testimonials /> */}
            <Blogs />
            <FormContainer />
        </div>
    );
};

export default Home01;